import React from 'react'
import { useState } from 'react'
import styled from "styled-components"
import HeaderTop from "./HeaderTop"
import HeaderCommon from "./HeaderCommon"
import Footer from "./Footer"
import ContactModal from "./ContactModal"
import { pc, sp, tab } from '../styles/media';
import { Helmet } from "react-helmet"
// import Modal from "react-modal";
import "../styles/destyle.css"
import "../styles/common.css"


const ContainerDiv = styled.div`
  width: 100%;
  max-width: 100%;
  font-size: 14px;
`
const ContentDiv = styled.div`
  ${sp`
    width: 100%;
    max-width: 100%;
    font-size: 11px;
    margin: 0 auto;
    margin-top: -80px;
    margin-bottom: 40px;
  `}
  ${pc`
    width: 80%;
    // max-width: 80%;
    // width: 1000px;
    max-width: 1200px;
    min-height: 500px;
    font-size: 14px;
    margin: 0 auto;
    margin-top: -180px;
    margin-bottom: 40px;
    // background-color: blue;
  `}
`

export default function Layout({ children, layoutType }) {

  const [showModal, setShowModal] = useState(false)
  
  const openModal = (e) => {
    e.preventDefault()
    setShowModal(true)
  }
  const closeModal = (e) => {
    e.preventDefault()
    setShowModal(false)
  }
  
  if(layoutType === "Top"){
    return (
      <ContainerDiv>
        <Helmet>
          <html lang="ja" />
          <title>【ビオエスト株式会社】｜関西エリアの倉庫・工場・土地情報</title>
          <meta name="description" content="倉庫・工場・土地等の運用についてお悩みなら、【ビオエスト株式会社】にご相談ください。
主に大阪・和歌山・奈良・兵庫エリアにて、賃貸・売買・管理・物件調査、査定を通じて適切な運用方法をご一緒に検討いたします。" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Noto+Sans+JP&subset=japanese" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          <link rel="icon" href="../images/favicon.png" type="image/png" />
          {/* <meta name="icon" href="../images/logo-black.png" /> */}
        </Helmet>
          <HeaderTop onOpenModal={(e) => openModal(e)} />
          <ContentDiv>
              { children }
          </ContentDiv>
          <Footer onOpenModal={(e) => openModal(e)} />
          {
            showModal && (
              <ContactModal onCloseModal={(e) => closeModal(e)} />
            )
          }
      </ContainerDiv>
  )
  }else{
    return (
        <ContainerDiv>
          <Helmet>
            <html lang="ja" />
            <title>【ビオエスト株式会社】｜関西エリアの倉庫・工場・土地情報</title>
            <meta name="description" content="倉庫・工場・土地等の運用についてお悩みなら、【ビオエスト株式会社】にご相談ください。
主に大阪・和歌山・奈良・兵庫エリアにて、賃貸・売買・管理・物件調査、査定を通じて適切な運用方法をご一緒に検討いたします。" />
          </Helmet>
            <HeaderCommon onOpenModal={(e) => openModal(e)} layoutType={layoutType}/>
            <ContentDiv style={{"margin-top": "0px", "padding-top": "150px"}}>
                { children }
            </ContentDiv>
            <Footer onOpenModal={(e) => openModal(e)} />
            {
              showModal && (
                <ContactModal onCloseModal={(e) => closeModal(e)} />
              )
            }
        </ContainerDiv>
    )
  }
}
