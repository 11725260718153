// import { Link } from 'gatsby'
import React from 'react'
import styled from "styled-components"
import {useStaticQuery, graphql} from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import { pc, sp, tab } from '../styles/media';

const DivMv = ({children}) => {
    const data = useStaticQuery(graphql`
    query {
        file(relativePath: { eq: "no-image-white.jpeg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
      }
    `)
    console.log("data:", data.file.childImageSharp)
    const imageData = getImage(data.file)
    console.log("imageData:", imageData)
    const bgImage = convertToBgImage(imageData)

    return (
        <BackgroundImage
        style={{
          "backgroundPosition": "0px -700px"
        }}
          Tag="section"
          // Spread bgImage into BackgroundImage:
          {...bgImage}
          preserveStackingContext
        //   Tag="section"
        //   className={className}
        //   fluid={imageData}
        //   backgroundColor={`#040e18`}
        >
          {/* <div style={{minHeight: 1000, minWidth: 100}}>
            <GatsbyImage image={imageData} alt={"testimage"}/>
          </div> */}
          {children}
        </BackgroundImage>
    )
}
// export const DivMv = () => <StaticImage src="../images/mv.jpg" alt="MainVisual" width={160} />;

const StyledDivMv = styled(DivMv)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledDivMv