import React from 'react'
import styled from "styled-components"
import {useState} from "react"
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { pc, sp, tab } from '../styles/media';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 100;
`
const FormContainer = styled.div`
    ${sp`
    width: 90%;
    `}
    width: 50%;
    height: 80%;
    background: white;
    padding: 20px;
    overflow: scroll;
`
const ContactTitle = styled.div`
    ${sp`
    font-size: 22px;
    `}
    ${pc`
    font-size: 24px;
    `}
    text-align: center;
    font-weight: bold;
    border-bottom: solid 1px #cbd0d5;
`
const ContactCaption = styled.p`
    font-size: 12px;
    margin-top: 5px;
    font-weight: normal;
    text-align:left;
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    text-indent: -1em;
}
`
// 59C9AB
const bioGreen = "#59C9AB"
const buttonStyle = {
    backgroundColor: bioGreen
}

export default function ContactModal({onCloseModal}) {
    const [company, setCompany] = React.useState('');
    const [name, setName] = React.useState('');
    const [tel, setTel] = React.useState('');
    const [mail, setMail] = React.useState('');
    const [category, setCategory] = React.useState('物件を貸したい方・売りたい方');
    const [body, setBody] = React.useState('');
    const [submitDisabled, setSubmitDisabled] = React.useState(null);
    const categories = [
        {
            value: "物件を貸したい方・売りたい方",
            label: "物件を貸したい方・売りたい方",
        },
        {
            value: "物件を借りたい方・買いたい方",
            label: "物件を借りたい方・買いたい方",
        },
        {
            value: "その他",
            label: "その他",
        }
    ]

    const inputTelRef = React.useRef(null);
    const inputMailRef = React.useRef(null);
    const inputNameRef = React.useRef(null);
    const inputBodyRef = React.useRef(null);

    const [inputNameError, setInputNameError] = useState(false);
    const [inputTelError, setInputTelError] = useState(false);
    const [inputMailError, setInputMailError] = useState(false);
    const [inputBodyError, setInputBodyError] = useState(false);

    const [inputNameErrorMessage, setInputNameErrorMessage] = useState("");
    const [inputTelErrorMessage, setInputTelErrorMessage] = useState("");
    const [inputMailErrorMessage, setInputMailErrorMessage] = useState("");
    const [inputBodyErrorMessage, setInputBodyErrorMessage] = useState("");
    
    const handleCompanyChange = (event) => {
        setCompany(event.target.value);
    };
    const handleNameChange = (event) => {
        if (inputNameRef.current) {
            const nameRef = inputNameRef.current;
            if (!nameRef.validity.valid) {
                setInputNameErrorMessage("必須入力です")
                setInputNameError(true);
            } else {
                setInputNameErrorMessage("")
                setInputNameError(false);
                setName(event.target.value)
            }
        }
    };
    const handleTelChange = (event) => {
        if (inputTelRef.current) {
            const telRef = inputTelRef.current;
            if (!telRef.validity.valid) {
                setInputTelErrorMessage("正しい番号を入力してください")
                setInputTelError(true);
            } else {
                setInputTelErrorMessage("")
                setInputTelError(false);
                setTel(event.target.value)
            }
        }
    };
    const handleMailChange = (event) => {
        if (inputMailRef.current) {
            const mailRef = inputMailRef.current;
            if (!mailRef.validity.valid) {
                setInputMailErrorMessage("メールアドレスの形式が不正です")
                setInputMailError(true);
            } else {
                setInputMailErrorMessage("")
                setInputMailError(false);
                setMail(event.target.value)
            }
        }
    };
    
    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };

    const handleBodyChange = (event) => {
        if (inputBodyRef.current) {
            const bodyRef = inputBodyRef.current;
            if (!bodyRef.validity.valid) {
                setInputBodyErrorMessage("必須入力です")
                setInputBodyError(true);
            } else {
                setInputBodyErrorMessage("")
                setInputBodyError(false);
                setBody(event.target.value);
            }
        }
    };

    const submitForm = (event) => {
        // Validation Check
        if(
            inputNameRef.current.validity.valid===false
            || 
            inputTelRef.current.validity.valid===false
            ||
            inputMailRef.current.validity.valid===false
            ||
            inputBodyRef.current.validity.valid===false
        ){
            if(inputNameRef.current.validity.valid===false){
                setInputNameErrorMessage("必須入力です")
                setInputNameError(true);
            }
            if(inputTelRef.current.validity.valid===false){
                setInputTelErrorMessage("正しい番号を入力してください")
                setInputTelError(true);
            }
            if(inputMailRef.current.validity.valid===false){
                setInputMailErrorMessage("メールアドレスの形式が不正です")
                setInputMailError(true);
            }
            if(inputBodyRef.current.validity.valid===false){
                setInputBodyErrorMessage("必須入力です")
                setInputBodyError(true);
            }
            alert("入力項目を確認してください")
            return false
        }
        const server = 'https://api.bio-est.co.jp/v1/send-ses'
        setSubmitDisabled("true")
        if(window.confirm("以下の内容で送信しますか？")){
            let params = {
                company: company,
                name: name,
                tel: tel,
                mail: mail,
                category: category,
                body: body,
            }    
            axios.post(server, params)
            .then((res) => {
                let msg = "お問い合わせを受け付けました。\n改めて担当からご連絡させて頂きます。"
                console.log(res)
                alert(msg)
                onCloseModal(event)
            })
            .catch((error) => {
                let msg = "エラーが発生しました。\nお手数ですがお電話にてお問い合わせいただきますようお願いいたします。"
                console.log(error)
                alert(msg)
            });
        }
        setSubmitDisabled(null)
    }

    return (
        <Container onClick={(e) => onCloseModal(e)}>
            <FormContainer onClick={(e) => {e.stopPropagation()}}>
                <ContactTitle>
                お気軽にお問い合わせ下さい
                </ContactTitle>
                <ContactCaption>
                ※迷惑メールフォルダやゴミ箱に自動的に振り分けられている可能性がありますので、<br/>一度ご確認頂きますようお願い致します。
                </ContactCaption>
                <div class="Form">
                    <div class="Form-Item">
                        <TextField id="company" label="会社名" variant="outlined" placeholder="例）株式会社令和" fullWidth size="small"
                            onChange={handleCompanyChange}
                        />
                    </div>
                    <div class="Form-Item">
                        <TextField id="name" label="氏名" variant="outlined" placeholder="例）山田太郎" required fullWidth size="small"
                            inputProps={{ minLength: 1, pattern: "^.+$" }}
                            error={inputNameError}
                            inputRef={inputNameRef}
                            onChange={handleNameChange}
                            helperText={inputNameErrorMessage}
                        />
                    </div>
                    <div class="Form-Item">
                        <TextField id="tel" label="電話番号" variant="outlined" placeholder="例）000-0000-0000" fullWidth size="small"
                            inputProps={{ maxLength: 13, pattern: "^[0-9\-]+$" }}
                            error={inputTelError}
                            inputRef={inputTelRef}
                            onChange={handleTelChange}
                            helperText={inputTelErrorMessage}
                        />
                    </div>
                    <div class="Form-Item">
                        <TextField id="mail" label="メールアドレス" variant="outlined" placeholder="例）example@gmail.com" required fullWidth size="small"
                            inputProps={{pattern: "^.+@.+\..+$" }}
                            error={inputMailError}
                            inputRef={inputMailRef}
                            onChange={handleMailChange}
                            helperText={inputMailErrorMessage}
                        />
                    </div>
                    <div class="Form-Item">
                        <TextField
                            id="category"
                            select
                            label="問い合わせカテゴリ"
                            value={category}
                            onChange={handleCategoryChange}
                            fullWidth
                            size="small"
                            >
                            {categories.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div class="Form-Item">
                        <TextField 
                            id="body" 
                            multiline 
                            rows={4} 
                            label="お問い合わせ内容" 
                            variant="outlined" 
                            placeholder="気になる物件名、ご希望事項、お悩みの内容等についてご入力ください" 
                            required 
                            fullWidth
                            onChange={handleBodyChange}
                            inputRef={inputBodyRef}
                            error={inputBodyError}
                            helperText={inputBodyErrorMessage}
                        />
                    </div>
                    <div class="Form-Item" style={{justifyContent: "center"}}>
                        <Button variant="contained" disabled={submitDisabled} onClick={(e) => submitForm(e)} style={buttonStyle}>送信する</Button>
                    </div>
                </div>
            </FormContainer>

        </Container>
    )
}
