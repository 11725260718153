// import { Link } from 'gatsby'
import React from 'react'
import styled from "styled-components"
import { Link } from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import { pc, sp, tab } from '../styles/media';
import useMedia from 'use-media';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';

import StyledDivMv from "./DivMv"

const bioGreen = "#59C9AB"

const HeaderContainer = styled.header`
  width: 100%;
  max-width: 100%;
  height: 300px;
  ${sp`
  height: 200px;
  `}

`
const HeaderSection = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-rows: 10px 100px;
  grid-template-columns: 20% 20% 20% 20% 20%;
`
const HeaderLogoArea = styled.h1`
    ${pc`
    max-width: 300px;
    justify-self: center;
    // grid-row: 1 / 3;
    // grid-column: 2 / 5;    
    grid-area: 1 / 1 / 3 / 3;
    `}
    ${sp`
    max-width: 300px;
    justify-self: center;
    grid-row: 1 / 3;
    grid-column: 1 / 4;    
    `}
    display: flex;
    align-items: center;
    mix-blend-mode: multiply;
`
const HeaderCaptionArea = styled.p`
    ${sp`
    grid-row: 1 / 2;
    grid-column: 4 / 6;    
    padding-top: 18px;
    text-indent: 0.5em;
    `}
    ${pc`
    // grid-row: 1 / 2;
    // grid-column: 5 / 6;
    grid-area: 1 / 4 / 2 / 6;
    text-decoration: underline;
    font-weight: bold;
    font-size: larger;
    padding-top: .75em;
    `}
    text-align: center;
`
const HeaderNavArea = styled.ul`
    ${pc`
    grid-row: 2 / 3;
    grid-column: 3 / 6;
    flex-direction: row;
    gap: 10px;
    justify-content: end;
    align-items: center;
    padding-right: 9.5vw;
    `}
    ${sp`
    grid-row: 2 / 3;
    grid-column: 4 / 6;
    flex-direction: row;
    width: 100%;
    `}
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    // gap: 30px;
    justify-content: center;
    justify-self: end;
}
`
const HeaderNavItemTel = styled.li`
    ${pc`
        // width: calc((100% - 30px * 2) / 3);
        // cursor: pointer;
        // background-color: rgb(89, 201, 171);
        // color: rgb(89, 201, 171);
        align-items: center;
        border-radius: 4px;
        min-width: 64px;
        padding: 12px 8px 4px 8px;
        color: black;
        display: inline-flex;
        justify-content: center;
        // box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
        & p{
            font-size: 22px;
        }
        & a{
            font-size: 22px;
        }
    `}
    ${sp`
    font-size: 10px;
    font-weight: 400;
    min-width: 5em;
    `}
    text-align: center;
`
const HeaderNavItem = styled.li`
    ${pc`
        // width: calc((100% - 30px * 2) / 3);
        cursor: pointer;
        background-color: rgb(89, 201, 171);
        align-items: center;
        border-radius: 4px;
        min-width: 64px;
        // padding: 4px 20px 4px 20px;
        padding: 10px;
        color: white;
        display: inline-flex;
        justify-content: center;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
        // font-size: 20px;
    `}
    ${sp`
    font-size: 10px;
    font-weight: 400;
    `}
    text-align: center;
`
const HeaderCopy = styled.h2`
      font-size: 24px;
      font-weight: bold;
      grid-row: 2 / 3;
      grid-column: 1 / 3;
`
const HeaderCaption = styled.span`
      display: block;
      font-size: 24px;
      padding-top: 8px;
`
const LinkStyles = {
    // "display": "flex",
    // "flex-direction": "column",
    // "padding": "10px 0",
    // "font-weight": "700",
    // "text-align": "center"
}

const Logo = () => {
    const isPc = useMedia({minWidth: '560px'});
    if(isPc){
        return (
            <StaticImage
                // src="../images/logo.png"
                src="../images/logo_header.jpeg"
                alt="BIOEST ロゴ"
                placeholder="blurred"
                // layout="fixed"
                // width={200}
                // height={200}
            />
        )
    }else{
        return (
            <StaticImage
                // src="../images/logo-black.png"
                src="../images/logo_header.jpeg"
                alt="BIOEST ロゴ"
                placeholder="blurred"
                // layout="fixed"
                width={200}
                // height={200}
            />
        )
    }
}


export default function HeaderTop({onOpenModal}) {
    const isPc = useMedia({minWidth: '560px'});
    return (
        <StyledDivMv>
            <HeaderContainer>
                <HeaderSection>
                    <HeaderLogoArea>
                        <Link to="/">
                            <Logo />
                        </Link>
                    </HeaderLogoArea>
                    <HeaderCaptionArea>
                        {
                            isPc ? "お問い合わせ" : "お問い合わせ"
                        }
                    </HeaderCaptionArea>
                    <HeaderNavArea>
                    <HeaderCaptionArea>
                        {
                            isPc ? "" : ""
                        }
                    </HeaderCaptionArea>
                        {
                            isPc ? <HeaderNavItemTel>
                            <CallIcon
                                edge="start"
                                color={isPc ? "white" : ""}
                                style={isPc ? {cursor: "default"} : {color: bioGreen}}
                            >
                            </CallIcon>
                            <a style={{"cursor": "default"}}>
                                072-268-2039
                            </a>
                            </HeaderNavItemTel> : <HeaderNavItemTel>
                            <a href="tel:072-268-2039">
                            <CallIcon
                                edge="start"
                                color={isPc ? "white" : ""}
                                style={isPc ? null : {color: bioGreen}}
                            >
                            </CallIcon>
                            <p>
                            電話
                            </p>
                            </a>
                            </HeaderNavItemTel>
                        }
                        <HeaderNavItem onClick={(e) => onOpenModal(e)}>
                        <MailIcon
                            edge="start"
                            color={isPc ? "white" : ""}
                            style={isPc ? null : {color: bioGreen}}
                        >
                        </MailIcon>
                        <p>
                            メッセージ
                        </p>
                        </HeaderNavItem>
                    </HeaderNavArea>
                    {/* <HeaderCopy>ビオエスト株式会社<HeaderCaption>【事業用不動産】　【倉庫・工場】　【不動産の仲介・相談】</HeaderCaption></HeaderCopy> */}
                </HeaderSection>
            </HeaderContainer>
        </StyledDivMv>
    )
}
