import { Link } from 'gatsby'
import React from 'react'
import styled from "styled-components"
import { pc, sp, tab } from '../styles/media';

const PropertyContainer = styled.div`
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 12%);
    border-radius: 3px;
    padding: 15px 0px;
`
const PropertyArea = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    justify-content: center;
`
const PropertyItem = styled.p`
${sp`
    padding: 0px;
`}
${pc`
    padding: 30px;
    letter-spacing: 15px;
`}
    font-size: 18px;
    font-weight: bold;
    justify-content: center;
    display: flex;
    align-items: center;
`
const PropertyStyle = {  // ::before属性は css に記述
    "width": "calc((100% - 10px * 2 - 10px * 2) / 3)",
    "padding": "5px",
    "display": "flex",
    "justify-content": "center",
    "align-items": "center",
    "background": "white",
    "flex-direction": "column",
    "border-radius": "3px",
    "z-index": "1",
    "opacity": "0.85",
    "border": "solid 2px #33333399",
}
const TabTitle = styled.h2`
    ${pc`
    font-size: 24px;
    margin: 20px 0px;
    `}
    ${sp`
    font-size: 22px;
    margin: 20px 0px 10px;
    `}
    // border-bottom: solid 1px #cbd0d5;
    font-weight: bold;
    padding: 10px 0px;
    margin: 20px 0px;
`
const TabTitleCaption = styled.p`
    ${pc`
    padding: 10px 0px;
    `}
    ${sp`
    padding: 10px 5px;
    `}
    font-size: 18px;
    font-weight: normal;
    border-bottom: solid 1px #cbd0d5;
    margin-bottom: 10px;
`
export default function SearchPropertySection({ layoutType }) {

    const propertiesObj = [
        {id: "1", title: "募集中物件"}, 
        {id: "2", title: "成約済物件"}, 
        {id: "3", title: "全物件"}
    ]

    if(layoutType==="Top"){
        return (
            <div>
                <TabTitle>
                    不動産の有効活用をお考えの方へ<br />
                </TabTitle>
                <TabTitleCaption>
                    私たちは不動産賃貸借・売買を通じて双方がご希望されますことを可能な限り実現いただけますように意識し、業務に取り組んでおります。
                </TabTitleCaption>
                <PropertyContainer>    
                    <PropertyArea>
                        {propertiesObj.map((content) => {
                            return (
                                <Link
                                    to="/properties"
                                    style={PropertyStyle}
                                    className="SquareCardStyle"
                                    key={content.id}
                                    state={{SearchId: `${content.id}`}}
                                >
                                <PropertyItem key={content.id}>{content.title}</PropertyItem>
                                </Link>
                            )})
                        }
                    </PropertyArea>
                </PropertyContainer>
            </div>
        )
    }else{
        return(

            <PropertyContainer
                style={{
                    width: "100%",
                    maxWidth: "1200px",
                    margin: "0 auto",
                }}
            >
                <PropertyArea>
                    {propertiesObj.map((content) => {
                        return (
                            <Link
                                to="/properties"
                                style={PropertyStyle}
                                className="SquareCardStyle"
                                key={content.id}
                                state={{SearchId: `${content.id}`}}
                            >
                            <PropertyItem key={content.id}>{content.title}</PropertyItem>
                            </Link>
                        )})
                    }
                </PropertyArea>
            </PropertyContainer>
        )
    }
}