import React from 'react'
import {graphql, useStaticQuery, Link} from 'gatsby'
import styled from "styled-components"
import {StaticImage} from "gatsby-plugin-image"
import { pc, sp, tab } from '../styles/media';
import useMedia from 'use-media';

const FooterContainer = styled.footer`
    ${sp`
    width: 100%;
    max-width: 100%;
    `}
    ${pc`
    width: 60%;
    max-width: 80%;
    `}
    // min-height: 500px;
    font-size: 14px;
    margin: 0 auto;
    padding-top: 15px;
    // background-color: #241916;
    background: linear-gradient(rgba(226,226,226), white);
    // color: white;
`
const FooterArea = styled.div`
    ${sp`
    grid-template-columns: 34% 64%;
    grid-template-rows: 80px 50px;
    `}
    ${pc`
    grid-template-columns: 300px 1fr;
    grid-template-rows: 60px 10px;
    `}
    display: grid;
    flex-wrap: wrap;
    align-items: center;
    // gap: 30px;
    justify-content: flex-end;
`
const FooterLogoCell = styled.div`
    ${sp`
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    margin-top: 10px;
    `}
    ${pc`
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    padding-left: 200px;
    `}
    mix-blend-mode: multiply;
    margin-top: -1px;
    margin: auto;
    & table {
        margin-left: 20px;
    }
`
const FooterNavCell = styled.div`
    ${sp`
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    padding-top: 20px;
    font-size: 12px;
    `}
    ${pc`
    `}
    // padding-left: 80px;
    text-align: left;
    & table {
        td {
            padding: 1px;
        }
    }
`
const FooterCaptionCell = styled.div`
    ${sp`
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    `}
    ${pc`
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    `}
    text-align: center;
    margin-top: 26px;
    font-size: 12px;
    padding-bottom: 5px;
`

const Logo = () => {
    // 602 × 783
    const isPc = useMedia({minWidth: '560px'});
    if(isPc){
        return (
            <StaticImage
                // src="../images/logo.png"
                src="../images/footer_logo-black.png"
                alt="BIOEST ロゴ"
                placeholder="blurred"
                layout="fixed"
                width={40}
                height={52}
            />
        )
    }else{
        return (
            <StaticImage
                // src="../images/logo.png"
                src="../images/footer_logo-black.png"
                alt="BIOEST ロゴ"
                placeholder="blurred"
                layout="fixed"
                width={58.4}
                height={76}
            />
        )
    }
}

export default function Footer({onOpenModal, onCloseModal}) {
    const isPc = useMedia({minWidth: '560px'});

    const data = useStaticQuery(graphql`
        query SiteInfos {
            site {
                siteMetadata {
                copyright
                description
                title
                }
            }
        }  
    `)
    const {copyright} = data.site.siteMetadata
    return (
        <FooterContainer>
            <FooterArea>
                <FooterLogoCell>
                <Logo />
                </FooterLogoCell>
                <FooterNavCell>
                    {
                        isPc ? <table>
                            <tr>
                                <th></th>
                                <td>ビオエスト株式会社　大阪府知事免許（１）第６１８５２号</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td>〒592-8343　大阪府堺市西区浜寺元町６丁８０８番地</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td><span>TEL <a>072-268-2039</a></span>　　FAX 072-268-2102</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td></td>
                            </tr>
                            {/* <tr>
                                <th>免許番号</th>
                                <td>大阪府知事免許（１）第６１８５２号</td>
                            </tr> */}
                        </table> : <table>
                            <tr>
                                <th></th>
                                <td>ビオエスト株式会社</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td>大阪府知事免許（１）第６１８５２号</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td>〒592-8343</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td>大阪府堺市西区浜寺元町６丁８０８番地</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td><span>TEL <a href="tel:072-268-2039">072-268-2039</a></span></td>
                            </tr>
                            <tr>
                                <th></th>
                                <td>FAX 072-268-2102</td>
                            </tr>
                            {/* <tr>
                                <th>免許番号</th>
                                <td>大阪府知事免許（１）第６１８５２号</td>
                            </tr> */}
                        </table>
                    }
 
                    {/* <p onClick={(e) => onOpenModal(e)} style={{"cursor": "pointer"}}>お問い合わせ</p> */}
                </FooterNavCell>
                <FooterCaptionCell>
                    <p>{copyright}</p>
                </FooterCaptionCell>
            </FooterArea>
        </FooterContainer>
    )
}
